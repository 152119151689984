.App {
  text-align: center;
  font-family: RobotoFlex;
}

@font-face {
  font-family: RobotoFlex;
  src: url("./assets/RobotoFlex-Regular.ttf") format("truetype");
}

.nav-logo{
  height: 5vmin;
  justify-self: center;
}

.navbar-container {  
  gap: 8px;  
  display: flex;
  justify-content: center;  
  align-items: center;    
  position: fixed;
  width: 100%;
  z-index: 2;
}

.navigation-items {  
  margin-top: 10px;
  padding: 0px 8px;
  background:linear-gradient(0deg, rgba(211, 218, 223, 0.502), rgba(211, 218, 223, 0.502));
  border-radius: 24px;
  /* font-family: Roboto; */
  text-align: center;    
    cursor: pointer;    
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    /* justify-content: center;   */
    align-items: center;
}

.nav-mobile {
  width: 100%;
  padding: 0px 8px;
  flex-direction: column;
  background:linear-gradient(0deg, rgba(211, 218, 223, 0.502), rgba(211, 218, 223, 0.502));
}

.navigation-items a, .nav-mobile a{
  text-decoration: none;
  font-weight: 400;
  color: #101112;  
  padding: 8px 16px;    
}

.nav-link:hover, .nav-linkActive {
  font-weight: 600;
  border-radius: 500px;
  background-color: rgba(184, 191, 196, 0.502);
  /* background:linear-gradient(0deg, rgba(184, 191, 196, 0.502, rgba(211, 218, 223, 0.502))); */
}

.section {
  height: auto;
  display: flex;
}

/* HERO */

.landing {  
  /* background:linear-gradient(0deg, rgba(36, 38, 40, 0.5), rgba(36, 38, 40, 0.5)), url("./images/landing-img.webp"); */
  background-image: url("./images/aggregates-crusher.webp");
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center;
  width: 100%;  
  align-items: center;  
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.landing-container {  
  margin-top: 200px;
  display: flex;
  flex-direction: column;  
  padding: 32px, 12px, 32px, 12px;
  align-items: center;
}

.landingLogo {
  height: 100px;
  width: 100px;
}

.companyName {
  font-size: 36px;
  color: white;
  font-weight: 600;
  line-height: 42px;
  margin: 12px;
}

.companyPhrase {
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 20px;
  color: white;
  line-height: 32px;
}

.landingBtn {
  background-color: #D01F44;
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500px;
  color: white;
  border-width: 0px;
}

.landingBtn:hover, .hero-auBtn:hover{
  cursor: pointer;
}

.linkTo-aboutUs {
  margin-top: 80px;
  text-align: center;  
  margin-bottom: 80px;
}

.landingAU-text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: white;
}

/* HERO END */

.sectionTitle {
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
}

.aboutUs {
  padding: 16px 140px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
}

.auContainer {
  display: flex;
  flex-direction: column;  
  align-items: center;
  width: 70%;
}

.auTextContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.auImage {
  width: 100%;
}


.auBtn {
  background-color: #D01F44;
  padding: 8px 12px;
  color: white;
  border-color: transparent;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.auBtn:hover {
  cursor: pointer;
}

/* PRODUCTS */
.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 140px;
  max-width: 100%;
}

.productsContainer {
  display: flex;
  /* gap: 40px; */
  margin: 20px 0px;
  justify-content: space-around;
  width: 70%;
  
}

.productContainer {
  width: 340px;
}

.productImage {
  width: 340px;
  height:200px;
  border-radius: 4px;
  object-fit: cover;
}

.productHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  margin: 2px;
}

.productText {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: justify;
}

/* CLIENTS */
.clients  {
  padding: 24px 140px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
}

.clients-container {
  max-width: 100%;
  display: flex;
  justify-items: center;
  /* align-items: center; */
  gap: 40px;
  text-align: center;
}

.clientsBtn {  
  appearance: none;
  border: none;
  background-color: transparent;
  margin-top: 180px;
}

.clientsBtn:hover {
  cursor: pointer;
}

.clientImg {
  width: 800px;
  height:400px;
  margin-bottom: 32px;
}

.clientLogo {
  width: 40px;
  height: 40px;

}

.clients .sectionTitle {
  margin-bottom: 2px;
}

.sectionSubtext {
  padding-bottom: 12px;
}

.slide-container {
  width: 70%;
  /* margin-top: 160px; */
  align-items: center;
}

.clientSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 8px; */
}

.clientsTitle, .clientsSubtitle, .clientsText{
  margin: 8px;
}

.clientsTitle {
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
}

.clientsSubtitle {
  color: #3498DB;
}

.clientsText, .clientsSubtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.indicator {
  display: none;
  width: 30px;
  color: blue;
  text-align: center;
  cursor: pointer;
  border: 1px blue solid;
}
.indicator.active {
  background: #000;
  color: #fff;
}


.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 140px;
  max-width: 100%;
}


.mapContainer {
  margin-top: 32px;
  display: flex;
  width: 80%;  
  align-items: center;
  justify-content: center;
}

.contactUsBtn {
  background-color: #D01F44;
  padding: 8px 12px;
  color: white;
  border-color: transparent;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.contactUsText {
  text-align: center;
  max-width: 60%;
}

.cuTextHeader {  
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;  
  margin: 12px;
}

.cuTextParagraph {  
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;  
}

/* FOOTER */

.footer {
  background-color: #101112;
  color: white;
  display: flex;
  padding: 20px 40px;
  gap: 12px;  
}

.footerLogo {
  width: 40px;
  height: 40px;
}

.footerHeader {
  margin: 0px;  
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;  
}

.footer-textContainer {
  display: flex;
  flex-direction: column;  
}

.footer-textContainer p {
  margin: 2px;
}

.mobileButtons {
  display: none !important;  
}

.cuMap {
  width: 800px;
  height: 320px;
}

.nav__toggle,
 .nav__close {
   display: none;
 }

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
 }
 
 .nav__link:hover {
  color: var(--first-color);
 }


 .nav-mobile, .navNobile-menu {
  display: none;
 }


/* MOBILE OPT */
@media only screen and (max-width: 768px){
  .landing {
    align-items: center;
    text-align: center;
    height: auto;
  }

  .sectionTitle {
    font-size: 36px;
  }

  .aboutUs, .products, .contactUs, .clients {
    padding: 16px;
    max-width: 100%;    
    height: auto;
    overflow-wrap: break-word;
  }
  .auContainer {
    max-width: 100%;
  }

  .auTextContainer {
    max-width: 100%;
  }

  .auImage {
    width: 100%;
  }

  .productsContainer {
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 12px
  }

  .productContainer {
    width: 100%;    
  }

  .clients {
    /* padding: 16px; */
    text-align: center;
  }

  .slide-container {
    width: 100%;
  }

  .desktopButtons {
    display: none;
  }

  .mobileButtons{
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    gap: 16px;   
    height: 32px !important; 
  }

  .clientsBtn {
    margin-top: 4px;   
  }

  .landing-arrowLeft, .landing-arrowRight {
    height: 32px;
  }
  
  .clients-container{
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
  }

  .clientImg {
    width: 100%;
    height: 260px;
    object-fit: inherit;
  }

  /* contact us section */

  .cu-Map {
    max-width: 288px;
    max-height: 200px;
  }

  .contactUsText {
    width: 100%;
    max-width: 100%;
  }

  .cuTextParagraph {
    line-break: normal;
    font-size: 16px;
  }

  
.nav__toggle,
.nav__close {
 font-size: 1.5rem;
 color: var(--title-color);
 cursor: pointer;
}

  .navigation-items {
    width: 100%;    
    border-radius: 24px ;  
    margin-top: 0;
  }

  .navbar-container {
    /* width: 100%; */        
    flex-direction: column;
    gap: 0px;
    box-sizing: border-box;
    padding: 16px 24px;    
  }

  .navNobile-menu {
    display: flex;    
    justify-content: center;

  }

  .nav-mobile {
    /* display: flex; */
    text-align: center;
    border-radius: 24px;
    transition:  0.4s;
  }

  .nav-desktop .nav-link {
    display: none;
  }

  .navMobile-menu {
    display: flex;
  }

  .nav__close {
    display: none;
   }
   
   .show-menu {
    display: flex;
   }



  /* .slide-container {
    width: 80%;
  } */
}





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
